export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  /*
  {
    title: 'Payments',
    route: 'home',
    icon: 'DollarSignIcon',
  },
  */
  {
    title: 'Create Operative',
    route: 'createoperative',
    icon: 'UserPlusIcon',
  },
  {
    title: 'Operatives',
    route: 'operatives',
    icon: 'ListIcon',
  },
  {
    title: 'Applicants',
    route: 'applicants',
    icon: 'FileTextIcon',
  },
  {
    title: 'Logout',
    route: 'logout',
    icon: 'LogOutIcon',
  },
]
